import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'

const FAQ: React.FC<Props<Faq>> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel0')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Container sx={{ position: 'relative', py: 8 }}>
      <Stack alignItems="center">
        <Typography
          variant="h1"
          noWrap
          fontWeight={800}
          sx={{ width: 1 }}
          color="accent.main"
        >
          {data?.sub}
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={700}
          sx={{ mt: '-70px' }}
        >
          {data?.titre}
        </Typography>
      </Stack>
      <Stack sx={{ py: 8 }}>
        {data?.liste?.map((el, i) => (
          <Accordion
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
            TransitionProps={{ unmountOnExit: true }}
            elevation={0}
            sx={{
              my: 1.5,
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              '&.MuiAccordion-root:before': {
                opacity: 0,
              },
            }}
            key={i}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${i}` ? (
                  <RemoveIcon color="primary" />
                ) : (
                  <AddIcon color="primary" />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">{el?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary">
                <ReactMarkdown>{el?.reponse}</ReactMarkdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Container>
  )
}

export default FAQ
